<template>
	<div data-component="video-viewer">
		<vue-plyr
			v-if="getSrc"
			:options="options"
		>
			<video
				controls
				playsinline
			>
				<source
					:src="getSrc"
					type="video/mp4"
				/>
			</video>
		</vue-plyr>
	</div>
</template>

<script>

	// data-poster="getPoster"

	export default {
		props: {
			src: {
				type: String,
				default: undefined
			}
		},
		data: () => ({
			options: {}
		}),
		computed: {
			getSrc () {
				if (!this.src) {
					return false;
				}
				return this.src;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='video-viewer'] {
	}
</style>
